import { Box, InputAdornment, Stack, Typography } from "@mui/material";

import React, { ChangeEvent, useEffect, useState } from "react";
import { CustomTable } from "../../global/components";
import LoginActivityStyles from "./LoginActivity.style";
import {
  fetchLoginActivityTableHandler,
  searchTableHandler,
} from "./service/loginActivity.service";
import { openErrorNotification } from "../../helpers/methods";
import { loginActivityTableHeader } from "./LoginActHeaders";

import moment from "moment";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";

const LoginActivity = () => {
  const classes = LoginActivityStyles;
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [perPageData, setPerPageData] = useState(10);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setPageNumber(1);
  }, [searchText, perPageData]);

  useEffect(() => {
    if (searchText) {
      searchDataHandler();
    } else {
      fetchTableLoginActivity();
    }
  }, [pageNumber, perPageData, searchText]);

  const fetchTableLoginActivity = async () => {
    try {
      setIsLoading(true);
      const res = await fetchLoginActivityTableHandler({
        input: { page: pageNumber, limit: perPageData },
      });
      const finalData = res?.loginActivityListAll?.data?.map((item: any) => {
        return {
          userInfo: (
            <Box>
              <Typography>{`Username: ${item?.user?.name}`}</Typography>
              <Typography>{`Email: ${item?.user?.email}`}</Typography>
              <Typography>{`RoleName: ${item?.user?.roleId?.name}`}</Typography>
              <Typography>{`AccountName: ${item.user?.account?.accountName}`}</Typography>
            </Box>
          ),
          systemInfo: (
            <Box>
              <Typography>
                {`Os: ${item.systemInfo?.workstation?.os?.name}
              version:${item.systemInfo?.workstation?.os?.version}`}
              </Typography>
              <Typography>{`Browser: ${item?.systemInfo?.workstation?.browser?.name}`}</Typography>
              <Typography>{`Version: ${item?.systemInfo?.workstation?.browser?.version}`}</Typography>
            </Box>
          ),
          location: (
            <Box>
              <Typography>
                {`Ip: ${
                  item.systemInfo.ipInfo.ip === "::1"
                    ? "127.0.0.1"
                    : item.systemInfo.ipInfo.ip
                }`}{" "}
              </Typography>
              <Typography>
                {item.systemInfo.ipInfo.city && (
                  <>
                    <h6>
                      Address:{" "}
                      {`${item.systemInfo.ipInfo.city}, ${item.systemInfo.ipInfo.region}, ${item.systemInfo.ipInfo.country}`}
                    </h6>
                    <h6>Location: {`${item.systemInfo?.ipInfo?.ll}`}</h6>
                  </>
                )}{" "}
              </Typography>
            </Box>
          ),
          //
          loginTime: (
            <Box>
              <Typography>
                <span>
                  {moment(item.loginTime).format("DD-MM-YYYY hh:mm:ss A")}{" "}
                  <br />({moment(item.loginTime).fromNow()})
                </span>
              </Typography>
            </Box>
          ),
          logoutTime: (
            <Box>
              <Typography>
                {item.logoutTime ? (
                  <span>
                    {moment(item.logoutTime).format("DD-MM-YYYY hh:mm:ss A")}{" "}
                    <br />({moment(item.logoutTime).fromNow()})
                  </span>
                ) : (
                  "Still Logged In"
                )}
              </Typography>
            </Box>
          ),
        };
      });
      setTableData(finalData);
      setCount(res?.loginActivityListAll?.paginatorInfo?.count);
      setIsLoading(false);
    } catch (error: any) {
      openErrorNotification(error.message);
      setIsLoading(false);
    }
  };

  const searchDataHandler = async () => {
    try {
      setIsLoading(true);
      const res = await searchTableHandler({
        input: {
          search: searchText,
          page: pageNumber,
          limit: perPageData,
        },
      });
      setTableData(res?.searchLoginActivity?.data);
      setCount(res?.searchLoginActivity?.paginatorInfo?.count);
      setIsLoading(false);
    } catch (error: any) {
      openErrorNotification(error.message);
      setIsLoading(false);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage);
  };

  const handleSearchOnChange = (SearchEvent: ChangeEvent<HTMLInputElement>) => {
    if (SearchEvent.target.value) {
      setSearchText(SearchEvent.target.value.trim());
      setPerPageData(10);
    } else {
      setSearchText("");
    }
  };

  const searchBarRole = () => {
    return (
      <Stack
        px={4}
        pt={2}
        direction={{ lg: "row", xs: "column" }}
        justifyContent="flex-end"
        alignItems={{ lg: "center" }}
      >
        <Typography mr={165} sx={classes.settingsTitle}>
          List of Login Activity
        </Typography>

        {/* <Stack
          direction={{ sm: "row", xs: "column" }}
          alignItems={{ sm: "center" }}
        >
          <CustomInput
            id="loginActivity_mgmt_search_field"
            placeHolder="Search Username"
            name="Username"
            onChange={debounceEventHandler(
              handleSearchOnChange,
              strings.SEARCH_TIME_OUT
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack> */}
      </Stack>
    );
  };

  const handlePerPageData = (event: any) => {
    setPageNumber(1);
    setPerPageData(event.target.value);
  };

  const loginActivityTableRender = () => {
    return (
      <>
        {searchBarRole()}
        <Box
          sx={{
            minWidth: "300px",
            overflow: "auto",
            padding: "30px",
          }}
        >
          <CustomTable
            headers={loginActivityTableHeader}
            rows={tableData}
            paginationCount={count}
            handlePageChange={handleChangePage}
            pageNumber={pageNumber}
            handlePerPageData={handlePerPageData}
            rowsPerPage={perPageData}
            perPageData={perPageData}
            setPage={setPageNumber}
            isLoading={isLoading}
          />
        </Box>
      </>
    );
  };

  return (
    <div className="bg-[#060B25] h-auto min-h-[100%] ">
      {loginActivityTableRender()} <CustomLoader isLoading={isLoading} />
    </div>
  );
};

export default LoginActivity;
