import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../helpers/methods";
import { CustomButton, CustomInput } from "../../global/components";
import HereMap from "./components/HereMap";
import { ListAllTrips, tripCount } from "./service/TripDashboard.service";
import { BsFillUnlockFill } from "react-icons/bs";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { debounceEventHandler } from "../../helpers/methods";
import axios from "axios";
import { updateTripStatus } from "../Trip/ActiveTrips/TripServices";
import urls from "../../global/constants/UrlConstants";
import strings from "../../global/constants/StringConstants";
import { store } from "../../utils/store";
import history from "../../utils/history";
import { FaLocationDot } from "react-icons/fa6";
import { FETCH_TRIP_STARTED_STATUS } from "./service/TripDashboard.mutation";
import { client } from "../../core-services/graphql/apollo-client";

interface Trip {
  tripId: string;
  name: string;
  tripData: {
    vehicleNo: string;
    imei: any;
  }[];
  startPoint: {
    name: string;
    latitude: number;
    longitude: number;
  };
  endPoint: {
    name: string;
    latitude: number;
    longitude: number;
  };
  status: string;
  tripEndDate: string;
  progress: string;
}

interface Stats {
  title: string;
  value: number;
}

const TripDashboard = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchData, setSearchData] = useState<string>("");
  const [trips, setTrips] = useState<Trip[]>([]);
  const [tripStats, setTripStats] = useState<any>();
  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  const [tripList, setTripList] = useState<string>("");

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const variables = {
          input: {
            accountId: store.getState().auth.tenantId,
            status: "started",
          },
        };
        const response = await ListAllTrips(variables);
        setTrips(response?.tripList?.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchTripsCount = async () => {
      try {
        const variables = {
          input: {
            accountId: store.getState().auth.tenantId,
          },
        };
        const response = await tripCount(variables);
        setTripStats(response?.tripCount);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    const showTripStartedStatus = async () => {
      setIsLoading(true);
      try {
        const accessToken = store.getState().auth.accessToken;

        const variables = {
          input: {
            accountId: store.getState().auth.tenantId,
            page: 1,
            limit: 10,
            status: "started",
          },
        };

        const response = await client.mutate({
          mutation: FETCH_TRIP_STARTED_STATUS,
          variables: variables,
          context: {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        });
        setTripList(response?.data?.fetchTripStartedStatus);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrips();
    fetchTripsCount();
    showTripStartedStatus();
  }, []);

  const stats: Record<string, Stats> = {
    executed: {
      title: "Today's Trips",
      value: tripStats?.todayActiveTripsCount ?? 0,
    },
    outbounds: {
      title: "Total Active Trips",
      value: tripStats?.totalActiveTripsCount ?? 0,
    },
  };

  const handleTripClick = (trip: Trip) => {
    setSelectedTrip(trip);
  };

  const unlockTrip = async (trip: Trip) => {
    try {
      const response = await axios.post(
        "http://192.168.1.20:5030/send-command",
        {
          imei: trip.tripData[0]["imei"][0],
        }
      );

      if (response?.data?.statusCode === 200) {
        openSuccessNotification("Lock successfully unlocked!");
        await updateTripStatus({
          input: {
            accountId: store.getState().auth.tenantId,
            tripId: trip.tripId,
            status: "ended",
          },
        });
      } else {
        openErrorNotification("Failed to unlock the lock.");
      }
    } catch (error: any) {
      openErrorNotification(
        error.message || "An error occurred while unlocking."
      );
    }
  };

  const buttonRender = (status: string, trip: any) => {
    switch (status) {
      case "started":
        return (
          <CustomButton
            label="Detail View"
            onClick={() => {
              history.push({
                pathname: `${urls.viewTripViewPath}/${trip.tripId}`,
                state: {
                  coordinates: [],
                  routeOrigin: [
                    {
                      lat: trip?.startPoint?.geoCodeData?.geometry
                        ?.coordinates[0],
                      lng: trip?.startPoint?.geoCodeData?.geometry
                        ?.coordinates[1],
                    },
                    {
                      lat: trip?.endPoint?.geoCodeData?.geometry
                        ?.coordinates[0],
                      lng: trip?.endPoint?.geoCodeData?.geometry
                        ?.coordinates[1],
                    },
                  ],
                },
              });
            }}
            customClasses={{
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "none",
              backgroundColor: "#5F22E1",
              color: "#fff",
              marginTop: "1rem",
            }}
          />
        );

      default:
        return (
          <CustomButton
            label="Unlock"
            startIcon={<BsFillUnlockFill />}
            onClick={() => {}}
            customClasses={{
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "none",
              backgroundColor: theme.palette.primary.main,
              fontSize: "14px",
              color: "#fff",
            }}
          />
        );
    }
  };

  const getTripList = () => {
    return (
      <Box
        sx={{
          padding: "1rem",
          backgroundColor: theme.palette.background.paper,
          border: "1px solid",
          borderColor: theme.palette.divider,
          borderRadius: "8px",
          height: "87vh",
          overflowY: "auto",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        }}
        className="navbarscroll"
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Geist_semibold",
            fontSize: "1rem",
            marginBottom: "1rem",
            borderLeft: "5px solid #5F22E1",
            paddingLeft: "0.5rem",
          }}
        >
          Trip List
        </Typography>
        <Box mb={2}>{getSearchBar()}</Box>
        {trips.map((trip) => (
          <Box
            key={trip.tripId}
            onClick={() => handleTripClick(trip)}
            sx={{
              border: "1px solid",
              borderColor: theme.palette.divider,
              padding: "16px",
              borderRadius: "12px",
              marginBottom: "16px",
              backgroundColor: theme.palette.background.paper,
              transition: "all 0.3s ease",
              "&:hover": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            {/* Vehicle Number */}
            <Typography
              sx={{
                fontFamily: "Geist_Bold",
                fontSize: "1.1rem",
                color: theme.palette.text.primary,
              }}
            >
              {trip.tripData[0]?.vehicleNo}
            </Typography>

            {/* Source (Start Point) */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <FaLocationDot className="text-[#00C532] mr-2" />
              <Typography
                sx={{
                  fontFamily: "Geist_Regular",
                  fontSize: "1rem",
                  color: theme.palette.text.secondary,
                }}
              >
                {trip.startPoint?.name}
              </Typography>
            </Box>

            {/* Destination (End Point) */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <FaLocationDot className="text-[#F60002] mr-2" />
              <Typography
                sx={{
                  fontFamily: "Geist_Regular",
                  fontSize: "0.9rem",
                  color: theme.palette.text.secondary,
                }}
              >
                {trip.endPoint?.name}
              </Typography>
            </Box>

            {/* Render action button */}
            <Box mt={2}>{buttonRender(trip.status, trip)}</Box>
          </Box>
        ))}
      </Box>
    );
  };

  const getStatsCard = () => {
    return (
      <Grid container spacing={2}>
        {Object.values(stats).map((stat) => (
          <Grid item xs={12} sm={6} key={stat.title}>
            <Box
              sx={{
                padding: "1rem",
                backgroundColor: theme.palette.background.paper,
                border: "1px solid",
                borderColor: theme.palette.divider,
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Geist_Medium",
                  color: theme.palette.text.primary,
                  fontSize: "16px",
                }}
              >
                {stat.title}
              </Typography>
              <Typography sx={{ fontSize: "32px", fontWeight: 700 }}>
                {stat.value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const getMap = () => {
    return (
      <Box
        sx={{
          height: "90vh",
          padding: "1rem",
          backgroundColor: theme.palette.background.paper,
          border: "1px solid",
          borderColor: theme.palette.divider,
          borderRadius: "8px",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        }}
      >
        <HereMap tripData={tripList} />
      </Box>
    );
  };

  const getSearchBar = () => {
    return (
      <CustomInput
        placeHolder="Search Trips"
        id="trip_search_field"
        onChange={debounceEventHandler(
          handleSearchOnChange,
          strings.SEARCH_TIME_OUT
        )}
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}
      />
    );
  };

  const handleSearchOnChange = (
    SearchEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchData(SearchEvent.target.value.trim().toLowerCase() || "");
  };

  const getDashboardBody = () => {
    return (
      <Grid container spacing={2} sx={{ padding: "1rem" }}>
        {/* On small screens, StatsCard should appear first, and it should take 9/12 space on large screens */}
        <Grid item xs={12} lg={9} order={{ xs: 1, lg: 2 }}>
          {getMap()}
        </Grid>

        {/* On small screens, TripList should appear third, and it should take 3/12 space on large screens */}
        <Grid item xs={12} lg={3} order={{ xs: 3, lg: 1 }}>
          {getStatsCard()}
          <div className="mt-4">{getTripList()}</div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: "100%",
        minHeight: "100vh",
        paddingTop: "4rem",
      }}
    >
      {getDashboardBody()}
      <CustomLoader isLoading={isLoading} />
    </Box>
  );
};

export default TripDashboard;
