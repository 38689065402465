export const loginActivityTableHeader = [
  {
    name: "Userinfo",
    field: "userInfo",
  },
  {
    name: "System Info",
    field: "systemInfo",
  },
  {
    name: "Location",
    field: "location",
  },
  {
    name: "Login Time",
    field: "loginTime",
  },
  {
    name: "Logout Time",
    field: "logoutTime",
  },
];
