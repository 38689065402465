import React, { useEffect, useRef, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  fetchAlertsTrackplayHandler,
  fetchGSMTrackplayHandler,
  fetchTrackplayHandler,
} from "./service/TripTrackplay.service";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Slider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomButton from "../../global/components/CustomButton/CustomButton";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import * as XLSX from "xlsx";
import moment from "moment";
import trackplayStyle from "./TripTrackplay.styles";
import { RiFileExcel2Fill } from "react-icons/ri";
import { store } from "../../utils/store";
import { PauseButton, PlayButton } from "../../utils/SidebarSvgConstant";
import LegendWidget from "./Component/LegendWidget";

interface TrackPlayProps {
  location?: any;
}

const TripTrackplay = ({ location }: TrackPlayProps) => {
  const trackPlayData = location.state;
  const classes = trackplayStyle;
  const [map, setMap] = useState<any>(null);
  const [speed, setSpeed] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stop, setStop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [timeoutIds, setTimeoutIds] = useState<number[]>([]);
  const currentMarkerRef = useRef<any>(null);
  const currentIndexRef = useRef(currentIndex);
  const speedRef = useRef(speed);
  const marks = [
    { value: 1, label: "1X" },
    { value: 2, label: "2X" },
    { value: 3, label: "3X" },
    { value: 4, label: "4X" },
  ];
  const [isStarted, setIsStarted] = useState(true);
  const redirectionState = location.state;
  const [speedPolylineVisible, setSpeedPolylineVisible] = useState(false);
  const [gsmPolylineVisible, setGsmPolylineVisible] = useState(false);
  const [alertsPolylineVisible, setAlertsPolylineVisible] = useState(false);
  const [speedPolylines, setSpeedPolylines] = useState<any[]>([]);
  const [gsmPolylines, setGsmPolylines] = useState<any[]>([]);
  const [alertsPolylines, setAlertsPolylines] = useState<any[]>([]);
  const [hasZoomed, setHasZoomed] = useState(false); // New state to track zoom

  const boundingBoxRef = useRef<any | null>(null);

  function addMarkersAtPolylineEnds(data: any, map: any) {
    if (!data || data.length < 2) return;

    const startCoordinates = {
      lat: data[0]?.latitude,
      lng: data[0]?.longitude,
    };
    const endCoordinates = {
      lat: data[data.length - 1]?.latitude,
      lng: data[data.length - 1]?.longitude,
    };

    const startIconMarkup = `<svg width="40px" height="40px" viewBox="-5.07 0 43.012 43.012" xmlns="http://www.w3.org/2000/svg">
      <path id="location" d="M406.185,260.012c-18.028-13.493-16.233-28.572-16.233-28.572h11.184a4.7,4.7,0,0,0-.142,1.1,5.378,5.378,0,0,0,.466,2.1,7.353,7.353,0,0,0,2.622,2.615,5,5,0,0,0,4.218,0,7.316,7.316,0,0,0,2.619-2.615,5.4,5.4,0,0,0,.465-2.105,4.728,4.728,0,0,0-.141-1.1h11.5S424.217,246.277,406.185,260.012Zm4.731-29.576a7.353,7.353,0,0,0-2.619-2.618,4.977,4.977,0,0,0-4.211,0,7.389,7.389,0,0,0-2.622,2.618,6.468,6.468,0,0,0-.326,1H389.966c0-7.972,7.335-14.435,16.383-14.435s16.383,6.463,16.383,14.435H411.242A6.523,6.523,0,0,0,410.915,230.436Z" transform="translate(-389.902 -217)" fill="#219C90"/>
    </svg>`;

    const endIconMarkup = `<svg width="40px" height="40px" viewBox="-5.07 0 43.012 43.012" xmlns="http://www.w3.org/2000/svg">
      <path id="location" d="M406.185,260.012c-18.028-13.493-16.233-28.572-16.233-28.572h11.184a4.7,4.7,0,0,0-.142,1.1,5.378,5.378,0,0,0,.466,2.1,7.353,7.353,0,0,0,2.622,2.615,5,5,0,0,0,4.218,0,7.316,7.316,0,0,0,2.619-2.615,5.4,5.4,0,0,0,.465-2.105,4.728,4.728,0,0,0-.141-1.1h11.5S424.217,246.277,406.185,260.012Zm4.731-29.576a7.353,7.353,0,0,0-2.619-2.618,4.977,4.977,0,0,0-4.211,0,7.389,7.389,0,0,0-2.622,2.618,6.468,6.468,0,0,0-.326,1H389.966c0-7.972,7.335-14.435,16.383-14.435s16.383,6.463,16.383,14.435H411.242A6.523,6.523,0,0,0,410.915,230.436Z" transform="translate(-389.902 -217)" fill="#EE4E4E"/>
    </svg>`;

    const startIcon = new window.H.map.Icon(startIconMarkup, {
      anchor: { x: 20, y: 24 },
    });
    const endIcon = new window.H.map.Icon(endIconMarkup, {
      anchor: { x: 20, y: 24 },
    });

    // Create markers for start and end points
    const startMarker = new window.H.map.Marker(startCoordinates, {
      icon: startIcon,
    });
    const endMarker = new window.H.map.Marker(endCoordinates, {
      icon: endIcon,
    });

    // Add the markers to the map
    map?.addObject(startMarker);
    map?.addObject(endMarker);
  }

  function addSpeedPolylineToMap(data: any) {
    const polylines = [];
    const speedColors = {
      40: "#007bff",
      20: "#FFAC34",
      0: "#FF2600",
    };

    if (!boundingBoxRef.current) {
      boundingBoxRef.current = new window.H.geo.Rect(
        Number(data[0]?.latitude),
        Number(data[0]?.longitude),
        Number(data[0]?.latitude),
        Number(data[0]?.longitude)
      );
    }

    for (let i = 0; i < data.length - 1; i++) {
      const point1 = data[i];
      const point2 = data[i + 1];
      const speed = point1.speed;

      let color;
      if (speed >= 40) {
        color = speedColors[40];
      } else if (speed >= 20) {
        color = speedColors[20];
      } else {
        color = speedColors[0];
      }

      const segmentLine = new window.H.geo.LineString();
      segmentLine.pushPoint({
        lat: Number(point1?.latitude),
        lng: Number(point1?.longitude),
      });
      segmentLine.pushPoint({
        lat: Number(point2?.latitude),
        lng: Number(point2?.longitude),
      });

      boundingBoxRef.current = boundingBoxRef.current.mergePoint({
        lat: Number(point1?.latitude),
        lng: Number(point1?.longitude),
      });
      boundingBoxRef.current = boundingBoxRef.current.mergePoint({
        lat: Number(point2?.latitude),
        lng: Number(point2?.longitude),
      });

      const polyline = new window.H.map.Polyline(segmentLine, {
        style: { lineWidth: 10, strokeColor: color },
      });

      polylines.push(polyline);
    }

    polylines.forEach((polyline) => map?.addObject(polyline));

    // Only zoom if it hasn't been zoomed yet
    if (!hasZoomed && boundingBoxRef.current) {
      map.getViewModel()?.setLookAtData({
        bounds: boundingBoxRef.current,
      });
      setHasZoomed(true); // Mark as zoomed
    }

    addMarkersAtPolylineEnds(data, map);
    setSpeedPolylines(polylines);
  }

  function addGSMPolylineToMap(data: any) {
    const polylines = [];
    const gsmColors = {
      high: "#00E396", // Strong signal
      medium: "#FFAC34", // Moderate signal
      low: "#FF2600", // Weak signal
    };

    let boundingBox = new window.H.geo.Rect(
      Number(data[0]?.latitude),
      Number(data[0]?.longitude),
      Number(data[0]?.latitude),
      Number(data[0]?.longitude)
    );

    for (let i = 0; i < data.length - 1; i++) {
      const point1 = data[i];
      const point2 = data[i + 1];
      const gsm = Math.round(point1.gsmSignal); // Round the GSM value to an integer

      let color;
      if (gsm >= 60) {
        color = gsmColors.high; // Use blue for strong signals
      } else if (gsm >= 30) {
        color = gsmColors.medium; // Use orange for moderate signals
      } else {
        color = gsmColors.low; // Use red for weak signals
      }

      const segmentLine = new window.H.geo.LineString();
      segmentLine.pushPoint({
        lat: Number(point1?.latitude),
        lng: Number(point1?.longitude),
      });
      segmentLine.pushPoint({
        lat: Number(point2?.latitude),
        lng: Number(point2?.longitude),
      });

      boundingBox = boundingBox.mergePoint({
        lat: Number(point1?.latitude),
        lng: Number(point1?.longitude),
      });
      boundingBox = boundingBox.mergePoint({
        lat: Number(point2?.latitude),
        lng: Number(point2?.longitude),
      });

      const polyline = new window.H.map.Polyline(segmentLine, {
        style: { lineWidth: 10, strokeColor: color },
      });

      polylines.push(polyline);
    }

    polylines.forEach((polyline) => map?.addObject(polyline));

    map.getViewModel()?.setLookAtData({ bounds: boundingBox });
    addMarkersAtPolylineEnds(data, map);
    setGsmPolylines(polylines);
  }

  function addAlertsPolylineToMap(data: any) {
    const polylines = [];
    let boundingBox = new window.H.geo.Rect(
      Number(data[0]?.latitude),
      Number(data[0]?.longitude),
      Number(data[0]?.latitude),
      Number(data[0]?.longitude)
    );

    for (let i = 0; i < data.length; i++) {
      const point = data[i];

      // Ignore alerts starting with "Firmware"
      if (point.alertMessage.startsWith("Firmware")) {
        continue;
      }

      const alertMarkerElement = document.createElement("div");
      alertMarkerElement.style.display = "flex";
      alertMarkerElement.style.alignItems = "center";
      alertMarkerElement.style.justifyContent = "center";
      alertMarkerElement.style.padding = "5px 10px";
      alertMarkerElement.style.borderRadius = "20px";
      alertMarkerElement.style.backgroundColor = "white";
      alertMarkerElement.style.border = "1px solid #ccc";
      alertMarkerElement.style.boxShadow = "0 2px 6px rgba(0,0,0,0.3)";
      alertMarkerElement.style.fontSize = "12px";
      alertMarkerElement.style.fontWeight = "bold";

      alertMarkerElement.innerHTML = `
        <span style="background-color: #FF5656; padding: 5px; border-radius: 50%; color: white;">
          ⚠️
        </span>
        <span style="margin-left: 5px;">${point.alertMessage.slice(
          0,
          10
        )}</span>
      `;

      const alertMarker = new window.H.map.DomMarker(
        { lat: Number(point?.latitude), lng: Number(point?.longitude) },
        {
          icon: new window.H.map.DomIcon(alertMarkerElement),
        }
      );

      map.addObject(alertMarker);
      polylines.push(alertMarker);

      boundingBox = boundingBox.mergePoint({
        lat: Number(point?.latitude),
        lng: Number(point?.longitude),
      });
    }

    if (!hasZoomed) {
      map.getViewModel()?.setLookAtData({
        bounds: boundingBox,
      });
      setHasZoomed(true); // Mark as zoomed
    }
    setAlertsPolylines(polylines);
  }

  const trackPlaySpeedApiHandler = async () => {
    if (speedPolylineVisible) {
      speedPolylines.forEach((polyline) => {
        map?.removeObject(polyline);
      });
      setSpeedPolylineVisible(false);
    } else {
      const trackdata = await fetchTrackplayHandler({
        input: {
          accountId: store.getState().auth.tenantId,
          imei: trackPlayData?.imei,
          startDate: trackPlayData?.startDate,
          endDate: trackPlayData?.endDate,
        },
      });

      addSpeedPolylineToMap(trackdata.getDistanceTrackPlay);
      setRawData(trackdata.getDistanceTrackPlay);
      setSpeedPolylineVisible(true);
    }
  };

  const trackPlayGSMApiHandler = async () => {
    if (gsmPolylineVisible) {
      // If GSM polyline is visible, remove it from the map
      gsmPolylines.forEach((polyline) => {
        map?.removeObject(polyline);
      });
      setGsmPolylineVisible(false); // Update the visibility state
    } else {
      // If polyline is not visible, fetch the data and add it
      const trackdata = await fetchGSMTrackplayHandler({
        input: {
          accountId: store.getState().auth.tenantId,
          imei: trackPlayData?.imei,
          startDate: trackPlayData?.startDate,
          endDate: trackPlayData?.endDate,
        },
      });


      if (
        trackdata &&
        trackdata.getDistanceTrackGsmSignalPlay &&
        trackdata.getDistanceTrackGsmSignalPlay.length > 0
      ) {
        addGSMPolylineToMap(trackdata.getDistanceTrackGsmSignalPlay);
        setRawData(trackdata.getDistanceTrackGsmSignalPlay);
        setGsmPolylineVisible(true); // Update the visibility state to true
      } else {
        console.error("GSM trackplay data is empty or malformed.");
      }
    }
  };

  const trackPlayAlertsApiHandler = async () => {
    if (alertsPolylineVisible) {
      // If alert polylines are visible, remove them from the map
      alertsPolylines.forEach((polyline) => {
        map?.removeObject(polyline);
      });
      setAlertsPolylineVisible(false); // Update the visibility state
    } else {
      // If alert polylines are not visible, fetch the data and add them
      const trackdata = await fetchAlertsTrackplayHandler({
        input: {
          accountId: store.getState().auth.tenantId,
          imei: trackPlayData?.imei,
          startDate: trackPlayData?.startDate,
          endDate: trackPlayData?.endDate,
        },
      });


      if (
        trackdata &&
        trackdata.getDistanceTrackAlertPlay &&
        trackdata.getDistanceTrackAlertPlay.length > 0
      ) {
        addAlertsPolylineToMap(trackdata.getDistanceTrackAlertPlay);
        setRawData(trackdata.getDistanceTrackAlertPlay);
        setAlertsPolylineVisible(true); // Update the visibility state to true
      } else {
        console.error("Alert trackplay data is empty or malformed.");
      }
    }
  };

  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);

  useEffect(() => {
    const platform = new window.H.service.Platform({
      apikey: "VkTYonrC2Hp9blylmhNUI96S5e8Y9sJLNlwtfFOdm_w",
    });

    const defaultLayers = platform.createDefaultLayers({
      tileSize: 256,
      ppi: 320,
    });

    const mapStyle = defaultLayers.vector.normal.map; // Apply the traffic map layer

    const initialMap = new window.H.Map(
      document.getElementById("map"),
      mapStyle,
      {
        center: { lat: 28.495831757053296, lng: 77.07923644083718 },
        zoom: 5,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    new window.H.mapevents.Behavior(
      new window.H.mapevents.MapEvents(initialMap)
    );

    setMap(initialMap); // Ensure map is set
    window.H.ui.UI.createDefault(initialMap, defaultLayers);
    initialMap.addLayer(defaultLayers.vector.normal.traffic);
    window.addEventListener("resize", () => initialMap.getViewPort().resize());

    return () => {
      window.removeEventListener("resize", () =>
        initialMap.getViewPort().resize()
      );
    };
  }, []);

  const test = () => {
    const interpolatedPoints = interpolatePoints(rawData);
    let newTimeouts: any[] = [];
    interpolatedPoints.forEach((item: any, index) => {
      const timeoutId = setTimeout(() => {
        const { lat, lng, direction } = item;
        animate(lat, lng, direction);
        setCurrentIndex(currentIndex + index + 1);
      }, (index * 100) / speedRef.current);
      newTimeouts.push(timeoutId);
    });
    setTimeoutIds(newTimeouts);
  };

  const interpolatePoints = (data: string | any[]) => {
    const points = [];
    for (let i = 0; i < data.length - 1; i++) {
      const start = data[i];
      const end = data[i + 1];
      const numInterpolations = 10;
      for (let j = 0; j < numInterpolations; j++) {
        const lat = interpolate(
          parseFloat(start?.latitude),
          parseFloat(end?.latitude),
          j / numInterpolations
        );
        const lng = interpolate(
          parseFloat(start?.longitude),
          parseFloat(end?.longitude),
          j / numInterpolations
        );
        const direction = interpolateDirection(
          start.direction,
          end.direction,
          j / numInterpolations
        );
        points.push({ lat, lng, direction });
      }
    }
    return points;
  };

  const interpolate = (start: number, end: number, factor: number) => {
    return start + (end - start) * factor;
  };

  const interpolateDirection = (start: number, end: number, factor: number) => {
    let delta = end - start;
    if (delta > 180) {
      delta -= 360;
    } else if (delta < -180) {
      delta += 360;
    }
    return start + delta * factor;
  };

  const animate = (lat: number, lng: number, direction: number) => {
    if (currentMarkerRef.current) {
      // Update marker position
      currentMarkerRef.current.setGeometry({ lat, lng });

      // Update rotation of the marker
      const svgElement = currentMarkerRef.current.getData();
      if (svgElement) {
        svgElement.style.transform = `rotate(${direction}deg)`;
        svgElement.style.transition = `transform 1s ease-in-out`;
        svgElement.style.transformOrigin = "center center";
      }
    } else {
      const domIconElement = document.createElement("div");
      domIconElement.style.margin = "-30px 0 0 -27px";
      domIconElement.classList.add("move-animation");

      domIconElement.innerHTML = `<svg width="60" height="60" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <circle cx="100" cy="100" r="80" fill="rgba(204, 230, 255, 0.4)" stroke="rgba(0, 123, 255, 0.4)" stroke-width="2" />
        <circle cx="100" cy="100" r="30" fill="#007bff" stroke="#ffffff" stroke-width="5" />
      </svg>`;

      const newMarker = new window.H.map.DomMarker(
        { lat, lng },
        {
          icon: new window.H.map.DomIcon(domIconElement, {
            onAttach: (clonedElement: any) => {
              const svgElement = clonedElement.querySelector("svg");
              if (svgElement) {
                svgElement.classList.add("rotate-animation");
                svgElement.style.transform = `rotate(${direction}deg)`;
                svgElement.style.transition = `transform 1s ease-in-out`;
                svgElement.style.transformOrigin = "center center";
              }
            },
          }),
        }
      );

      newMarker.setData(domIconElement.querySelector("svg"));

      map.addObject(newMarker);
      currentMarkerRef.current = newMarker;
    }
  };

  const handleSpeedChange = (event: any, newValue: any) => {
    let speedMultiplier;
    switch (newValue) {
      case 1:
        speedMultiplier = 2;
        break;
      case 2:
        speedMultiplier = 4;
        break;
      case 3:
        speedMultiplier = 8;
        break;
      case 4:
        speedMultiplier = 10;
        break;
      default:
        speedMultiplier = 2;
    }

    setSpeed(newValue);
    speedRef.current = speedMultiplier;

    // Clear existing timeouts
    if (timeoutIds.length > 0) {
      timeoutIds.forEach((id) => clearTimeout(id));
    }

    // Continue animation from the current position
    animateFromCurrentPosition();
  };

  const animateFromCurrentPosition = () => {
    const interpolatedPoints = interpolatePoints(rawData);
    let newTimeouts: any[] = [];
    interpolatedPoints.slice(currentIndexRef.current).forEach((item, index) => {
      const timeoutId = setTimeout(() => {
        const { lat, lng, direction } = item;
        animate(lat, lng, direction);
        setCurrentIndex((prevIndex) => {
          const newIndex = prevIndex + 1;
          currentIndexRef.current = newIndex;
          return newIndex;
        });
      }, (index * 100) / speedRef.current);
      newTimeouts.push(timeoutId);
    });
    setTimeoutIds(newTimeouts);
  };

  const handleClick = () => {
    if (timeoutIds.length > 0) {
      timeoutIds.forEach((id) => clearTimeout(id));
      setIsStarted((prevState) => !prevState);
    }
    test();
  };

  const generateExcelFile = () => {
    const modifiedData = rawData.map((item: any) => ({
      IMEI: item.imei,
      Vehicle_No: item.label,
      Latitude: item.lat,
      Longitude: item.lng,
      Time: item.currentTime
        ? moment(item.currentTime).format("DD-MM-YYYY HH:mm:ss A")
        : "",
    }));
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "TrackReport.xlsx");
  };

  return (
    <Box
      component={"div"}
      id="map"
      style={{
        width: "100%",
        zIndex: 1,
        height: "100%",
        position: "relative",
      }}
    >
      <CustomLoader isLoading={isLoading} />

      <LegendWidget
        speedPolylineVisible={speedPolylineVisible}
        gsmPolylineVisible={gsmPolylineVisible}
        alertsPolylineVisible={alertsPolylineVisible}
      />
      <Box
        sx={{
          position: "absolute",
          top: "4rem",
          right: "1.7rem",
          zIndex: "2",
          backgroundColor: "#060B25",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          padding: "1rem",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            justifyContent: "space-between",
            marginBottom: "1rem",
            minWidth: "200px",
          }}
        >
          <div className="text-white font-bold text-xl ">
            {redirectionState
              ? redirectionState.vehicleNumber
              : redirectionState?.imei}
          </div>
          <div className="text-white flex flex-col ">
            <div className="text-sm ">
              Start Date:
              <span className="text-xs opacity-70">
                {" "}
                {redirectionState.tripStartDate}
              </span>
            </div>
            <div className="text-sm ">
              End Date:{" "}
              <span className="text-xs opacity-70">
                {" "}
                {redirectionState.tripEndDate}
              </span>
            </div>
          </div>
        </Box>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="speed-scale"
          >
            <Typography>Plot</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              style={{
                width: 150,
                display: "flex",
                gap: "0.3rem",
                flexDirection: "column",
              }}
            >
              <CustomButton
                id="users_add_button"
                label={speedPolylineVisible ? "Hide Speed" : "Show Speed"} // Change label based on state
                onClick={trackPlaySpeedApiHandler}
                customClasses={{
                  width: "200px",
                }}
              />
              <CustomButton
                id="alert_button"
                label={alertsPolylineVisible ? "Hide Alerts" : "Show Alerts"} // Change label based on state
                onClick={() => {
                  trackPlayAlertsApiHandler();
                }}
                customClasses={{
                  width: "200px",
                }}
              />

              {/* <CustomButton
                id="users_add_button"
                label={"GPS Strength"}
                onClick={trackPlayApiHandler}
                customClasses={{
                  width: "170px",
                }}
              /> */}
              <CustomButton
                id="gsm_button"
                label={gsmPolylineVisible ? "Hide GSM " : "Show GSM "}
                onClick={() => {
                  trackPlayGSMApiHandler();
                }}
                customClasses={{
                  width: "200px",
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="speed-scale"
          >
            <Typography>Speed</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex">
              <div onClick={handleClick} className="mr-5 mt-1 cursor-pointer ">
                {isStarted ? <PlayButton /> : <PauseButton />}
              </div>

              <div style={{ width: 150, margin: "auto" }}>
                <Slider
                  aria-label="Restricted values"
                  value={speed}
                  onChange={handleSpeedChange}
                  valueLabelDisplay="auto"
                  defaultValue={0}
                  step={1}
                  marks={marks}
                  min={1}
                  max={4}
                  sx={{ height: 8 }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Reports</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button
              onClick={generateExcelFile}
              sx={{
                backgroundColor: stop ? "#ffffff" : "#FF5733",
                color: stop ? "#333" : "white",
                display: "flex",
                gap: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              <RiFileExcel2Fill />
            </Button>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default React.memo(TripTrackplay);
