import { gql } from "@apollo/client";

export const FETCH_LOGINACTIVITY = gql`
  mutation ($input: LoginActivityFetchInput!) {
    loginActivityListAll(input: $input) {
      paginatorInfo {
        count
      }
      success
      message
      data {
        user
        systemInfo
        loginTime
        logoutTime
      }
    }
  }
`;

export const SEARCH_LOGINACTIVITY_MODULE = gql`
  mutation ($input: SearchLoginActivityInput!) {
    searchLoginActivity(input: $input) {
      paginatorInfo {
        count
      }
      success
      message
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation logoutUser($input: LogoutInput!) {
    logoutUser(input: $input)
  }
`;
