import { gql } from "@apollo/client";

export const FETCH_TRACKPLAY_DATA = gql`
  mutation ($input: DistanceTrackPlayInputType!) {
    getDistanceTrackPlay(input: $input) {
      latitude
      longitude
      imei
      bearing
      speed
    }
  }
`;

export const FETCH_GSM_TRACKPLAY_DATA = gql`
  mutation ($input: DistanceTrackPlayInputType!) {
    getDistanceTrackGsmSignalPlay(input: $input) {
      latitude
      longitude
      imei
      bearing
      gsmSignal
    }
  }
`;

export const FETCH_ALERTS_TRACKPLAY_DATA = gql`
  mutation ($input: DistanceTrackPlayInputType!) {
    getDistanceTrackAlertPlay(input: $input) {
      latitude
      longitude
      time
      imei
      bearing
      alertMessage
    }
  }
`;

export const UPDATE_GEOZONE = gql`
  mutation ($input: UpdateGeozoneInput!) {
    updateGeozone(input: $input) {
      success
      message
    }
  }
`;
