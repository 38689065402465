import { Box, Typography } from "@mui/material";

const LegendWidget = ({
  speedPolylineVisible,
  gsmPolylineVisible,
  alertsPolylineVisible,
}: any) => {
  // Check if any polyline is visible
  if (!speedPolylineVisible && !gsmPolylineVisible && !alertsPolylineVisible) {
    return null; // Return null if no polyline is visible (hide the legend)
  }

  const speedColors = [
    { color: "#007bff", label: "Speed > 40" },
    { color: "#FFAC34", label: "Speed 20 - 40" },
    { color: "#FF2600", label: "Speed < 20" },
  ];

  const gsmColors = [
    { color: "#00E396", label: "GSM Strong (>= 60)" },
    { color: "#FFAC34", label: "GSM Moderate (30 - 59)" },
    { color: "#FF2600", label: "GSM Weak (< 30)" },
  ];

  const alertColors = [{ color: "#FF5656", label: "Alert" }];

  return (
    <Box
      sx={{
        position: "absolute",
        top: "4rem",
        left: "1rem", // Adjust position to fit inside the red-bordered region
        backgroundColor: "#060B25",
        borderRadius: "5px",
        zIndex: 2,
        color: "white",
        minWidth: "150px",
        padding: "1rem",
      }}
    >
      <Typography variant="h6">Plotted By:</Typography>

      {speedPolylineVisible && (
        <>
          <Typography variant="subtitle1">Speed</Typography>
          {speedColors.map((item) => (
            <Box
              key={item.color}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.3rem",
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: item.color,
                }}
              ></Box>
              <Typography>{item.label}</Typography>
            </Box>
          ))}
        </>
      )}
      {gsmPolylineVisible && (
        <>
          <Typography variant="subtitle1">GSM</Typography>
          {gsmColors.map((item) => (
            <Box
              key={item.color}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.3rem",
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: item.color,
                }}
              ></Box>
              <Typography>{item.label}</Typography>
            </Box>
          ))}
        </>
      )}
      {alertsPolylineVisible && (
        <>
          <Typography variant="subtitle1">Alerts</Typography>
          {alertColors.map((item) => (
            <Box
              key={item.color}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.3rem",
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: item.color,
                }}
              ></Box>
              <Typography>{item.label}</Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default LegendWidget;
