import React, { useEffect, useState } from "react";
import CustomLoader from "../../../global/components/CustomLoader/CustomLoader";

const HereMap = ({ tripData }: any) => {
  const [map, setMap] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    // Set a timeout for 7 seconds
    const timeoutId = setTimeout(() => {
      // If tripData is still empty after 7 seconds, display no data message
      if (!tripData || tripData.length === 0) {
        setIsLoading(false);
        setNoData(true);
      }
    }, 10000);

    // If tripData arrives before 7 seconds, clear the timeout and hide the loader
    if (tripData?.length) {
      setIsLoading(false);
      setNoData(false); // Ensure noData is false if we get data
      clearTimeout(timeoutId);

      // Iterate over tripData to add new markers
      tripData.forEach((trip: any) => {
        const { latitude, longitude } = trip;

        // Create a marker
        const domIconElement = document.createElement("div");
        domIconElement.style.margin = "-20px 0 0 -20px";
        domIconElement.innerHTML = `<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" fill="rgba(255, 255, 255, 0.5)" stroke="none" />
          <polygon points="50,20 70,65 50,55 30,65" fill="#7C58CB" stroke="#5F22E1" stroke-width="2" />
        </svg>`;

        const newMarker = new window.H.map.DomMarker(
          { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          {
            icon: new window.H.map.DomIcon(domIconElement),
          }
        );

        // Add the new marker to the map
        map.addObject(newMarker);
      });
    }

    // Clean up the timeout if the component unmounts or tripData changes
    return () => clearTimeout(timeoutId);
  }, [tripData, map]);

  useEffect(() => {
    // Initialize the map
    const platform = new window.H.service.Platform({
      apikey: "VkTYonrC2Hp9blylmhNUI96S5e8Y9sJLNlwtfFOdm_w",
    });

    const defaultLayers = platform.createDefaultLayers();

    const initialMap = new window.H.Map(
      document.getElementById("here-map"),
      defaultLayers.vector.normal.map,
      {
        center: { lat: 25.0, lng: 78.0 },
        zoom: 3,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    new window.H.mapevents.Behavior(
      new window.H.mapevents.MapEvents(initialMap)
    );
    window.H.ui.UI.createDefault(initialMap, defaultLayers);

    setMap(initialMap);

    return () => {
      if (initialMap) {
        initialMap.dispose();
      }
    };
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {/* Custom Loader */}
      <CustomLoader isLoading={isLoading} />

      {/* Show "No trip data exists" if no data is received after 7 seconds */}
      {noData && !isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 10,
          }}
        >
          <p style={{ fontSize: "18px", color: "#FF0000" }}>
            No trip data exists
          </p>
        </div>
      )}

      {/* Map container */}
      <div
        id="here-map"
        style={{ width: "100%", height: "100%", position: "relative" }}
      />
    </div>
  );
};

export default HereMap;
