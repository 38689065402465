import React from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Typography, useTheme, useMediaQuery } from "@mui/material";

const Speedometer = ({ speed }: { speed: any }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // For mobile screens
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md")); // For tablets

  const options: ApexOptions = {
    chart: {
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: "20%", // Adjusted based on screen size
        },
        track: {
          background: "#16497D",
          strokeWidth: "100%", // Thicker background track
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 20,
            color: theme.palette.text.primary,
            fontSize: isSmallScreen ? "12px" : "15px", // Adjust font size for smaller screens
            formatter: function (val) {
              return `${val} Km/h`;
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    yaxis: {
      min: 0,
      max: 150,
    },
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: isSmallScreen ? "10px" : "20px", // Adjust based on screen size
        right: isSmallScreen ? "10px" : "20px",
        zIndex: 1000,
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: isSmallScreen ? "5px" : "10px", // Smaller padding for smaller screens
        borderRadius: "5px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        display: "flex",
        flexDirection: "column",
        width: isSmallScreen ? "150px" : isMediumScreen ? "180px" : "200px", // Responsive width
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontFamily: "Geist_semibold",
          fontSize: isSmallScreen ? "0.8rem" : "0.9rem", // Adjusted font size
          marginBottom: "0.5rem",
          padding: "0.2rem 0.8rem",
          borderRadius: "5px",
          borderLeft: "7px solid",
          borderLeftColor: "#855BDE",
        }}
      >
        Speed (Live)
      </Typography>
      <ApexCharts
        options={options}
        series={[speed]}
        type="radialBar"
        height={isSmallScreen ? 140 : isMediumScreen ? 160 : 180} // Responsive height
        width={isSmallScreen ? 160 : isMediumScreen ? 180 : 200} // Responsive width
      />
    </div>
  );
};

export default Speedometer;
