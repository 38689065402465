import React, { useState } from "react";
import HereMapCluster from "./HereMapCluster";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";

const MapView = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="mt-[4rem]">
      {isLoading && <CustomLoader isLoading={isLoading} />}
      <HereMapCluster setLoading={setIsLoading} />
    </div>
  );
};

export default MapView;
