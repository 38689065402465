import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { Box, Typography, useTheme } from "@mui/material";

interface LineChartProps {
  width?: number | string;
  height?: number;
  dataGraph?: any;
}

const SpeedLineChart: React.FC<LineChartProps> = ({
  height = 120,
  dataGraph,
}) => {
  const theme = useTheme();
  const chartRef = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<ApexCharts | null>(null);

  useEffect(() => {
    const options: ApexCharts.ApexOptions = {
      chart: {
        type: "area",
        width: "100%",
        height: height,
        background: theme.palette.background.paper,
        foreColor: theme.palette.text.primary,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 1.5,
      },
      dataLabels: {
        enabled: false,
      },
      series: dataGraph?.series || [],
      xaxis: {
        categories: dataGraph?.xaxis?.categories,
      },
      yaxis: {
        opposite: theme.palette.mode === "dark",
      },
      grid: {
        borderColor: theme.palette.divider,
      },
      tooltip: {
        theme: theme.palette.mode,
      },
    };

    const chartInstance = new ApexCharts(chartRef.current, options);
    chartInstance.render();
    setChart(chartInstance);

    return () => {
      chartInstance.destroy();
    };
  }, [height, theme, dataGraph]);

  return (
    <Box
      sx={{
        padding: "0.2rem",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        border: "1px solid",
        borderColor: theme.palette.divider,
        color: theme.palette.text.primary,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "0.8rem",
          marginBottom: "0.3rem",
          padding: "0.2rem 0.8rem",
          borderRadius: "5px",
          borderLeft: "5px solid",
          borderLeftColor: "#855BDE",
        }}
      >
        Speed (Past 24 Hours)
      </Typography>
      <Box ref={chartRef} sx={{ height: height, width: "100%" }} />
    </Box>
  );
};

export default SpeedLineChart;
