import { useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { DEVICE_DATA } from "../../Dashboard/service/Dashboard.mutation";
import moment from "moment";
import { FaLock } from "react-icons/fa";
import { LiaSignalSolid } from "react-icons/lia";
import { FaMapPin } from "react-icons/fa";
import { FaCarBattery } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import SpeedLineChart from "./SpeedLineGraph";
import { batteryGraphData, speedGraphData } from "../service/routes.service";
import BatteryLineChart from "./BatteryLineGraph";
import { Box, Typography } from "@mui/material";
import Speedometer from "./Speedometer";
import { store } from "../../../utils/store";

interface LocationState {
  imei: string;
  status: string;
}

const ViewLiveTracking = () => {
  const [map, setMap] = useState<any>(null);
  const [currentMarker, setCurrentMarker] = useState<any>(null);
  const [trackData, setTrackData] = useState<any>(null);
  const [address, setAddress] = useState<string>("Loading...");
  const location = useLocation();
  const [graphData, setGraphData] = useState<any>();

  const apiKey = "VkTYonrC2Hp9blylmhNUI96S5e8Y9sJLNlwtfFOdm_w";

  const { imei, status } = location?.state as LocationState;

  useEffect(() => {
    const platform = new window.H.service.Platform({
      apikey: apiKey,
    });
    const defaultLayers = platform.createDefaultLayers();

    const initialMap = new window.H.Map(
      document.getElementById("map"),
      defaultLayers.vector.normal.map,
      {
        center: { lat: 28.495831757053296, lng: 77.07923644083718 },
        zoom: 8,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    new window.H.mapevents.Behavior(
      new window.H.mapevents.MapEvents(initialMap)
    );
    window.H.ui.UI.createDefault(initialMap, defaultLayers);
    setMap(initialMap);
    return () => {
      window.removeEventListener("resize", () =>
        initialMap.getViewPort().resize()
      );
      initialMap.dispose();
    };
  }, []);

  const { data } = useSubscription(DEVICE_DATA, {
    variables: {
      topicType: "track",
      accountId: store.getState().auth.tenantId,
      imeis: [imei],
    },
  });

  const getReverseGeocodingData = async (
    latitude: number,
    longitude: number
  ) => {
    const url = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude},${longitude}&lang=en-US&apikey=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.items && data.items.length > 0) {
        const address = data.items[0].address.label;
        return address;
      } else {
        return "No address available";
      }
    } catch (error) {
      console.error("Error during reverse geocoding: ", error);
      return "No address available";
    }
  };

  useEffect(() => {
    if (data?.track) {
      try {
        const trackJson = JSON.parse(data.track);
        setTrackData(trackJson);

        const { longitude, latitude, bearing, imei } = trackJson;
        map.setZoom(15);
        // Fetch address for the current location
        getReverseGeocodingData(latitude, longitude).then(setAddress);

        const domIconElement = document.createElement("div");
        domIconElement.style.margin = "-20px 0 0 -20px";

        domIconElement.innerHTML = `<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <circle cx="50" cy="50" r="40" fill="rgba(255, 255, 255, 0.5)" stroke="none" />
  <polygon points="50,20 70,65 50,55 30,65" fill="#7C58CB" stroke="#5F22E1" stroke-width="2" />
</svg>`;

        if (currentMarker) {
          map.removeObject(currentMarker);
        }
        const newMarker = new window.H.map.DomMarker(
          { lat: latitude, lng: longitude },
          {
            icon: new window.H.map.DomIcon(domIconElement, {
              onAttach: function (
                clonedElement: any,
                domIcon: any,
                domMarker: any
              ) {
                const clonedContent =
                  clonedElement.getElementsByTagName("svg")[0];
                clonedContent.style.transform = "rotate(" + bearing + "deg)";
              },
            }),
          }
        );
        setCurrentMarker(newMarker);
        map.addObject(newMarker);

        map.setCenter({ lat: latitude, lng: longitude });
        map.setZoom(15);
      } catch (error) {
        console.error("Error parsing JSON string:", error);
      }
    }
  }, [data]);

  useEffect(() => {
    graphDataHandler();
  }, []);

  const graphDataHandler = async () => {
    try {
      const [online, offline] = await Promise.all([
        speedGraphData({
          input: {
            accountId: store.getState().auth.tenantId,
            imei: imei,
          },
        }),
        batteryGraphData({
          input: {
            accountId: store.getState().auth.tenantId,
            imei: imei,
          },
        }),
      ]);

      setGraphData({
        online: online?.speedGraphData,
        offline: offline?.batteryGraphDataData,
      });
    } catch (error) {}
  };

  return (
    <>
      <Box
        style={{
          position: "relative",
          height: "100vh",
          paddingTop: "6rem",
          backgroundColor: "#070C35",
          color: "#FFFFFF",
          padding: "1rem", // Add padding for overall layout consistency
        }}
      >
        {/* Speed and Battery Graphs */}
        <Box
          sx={{
            position: "absolute",
            top: "4rem",
            left: "1%",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <SpeedLineChart dataGraph={graphData?.online} />
          <BatteryLineChart dataGraph={graphData?.offline} />
        </Box>

        {/* Speedometer */}
        <Box
          sx={{
            position: "absolute",
            top: "28%",
            right: "1%",
            zIndex: 1000,
          }}
        >
          {trackData && <Speedometer speed={trackData?.speed} />}
        </Box>

        {/* Main Map and Details */}
        <Box
          sx={{
            // position: "relative",
            width: "100%",
            height: "100%",
            pt: "4rem",
          }}
        >
          {/* Map */}
          <Box id="map" sx={{ width: "100%", height: "72%" }}></Box>

          {trackData && (
            <Box
              sx={{
                position: "absolute",
                bottom: 17,
                left: 0,
                // width: "100%",
                backgroundColor: "#070c35", // Slightly darker shade for the details area
                padding: "1.5rem",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {/* IMEI and Status Section */}
              <Box
                sx={{
                  backgroundColor: "#101840",
                  padding: "1rem",
                  borderRadius: "8px",
                  flex: "1",
                  width: "auto",
                  minWidth: "200px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                >
                  <FaLock />
                  {trackData.imei}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Status:{" "}
                  <span className="bg-green-500 text-white rounded px-2">
                    {status}
                  </span>
                </Typography>
                <Typography variant="body2">
                  Connection:{" "}
                  {!trackData?.statusBitDefinition?.connection ? (
                    <span>Connected</span>
                  ) : (
                    <span>Not Connected</span>
                  )}
                </Typography>
                <Typography variant="body2">
                  Last Updated: {moment(trackData.dateTime).fromNow()}
                </Typography>
                <Typography variant="body2">
                  Date/Time:{" "}
                  {moment(trackData.dateTime).format("DD-MM-YYYY HH:mm:ss")}
                </Typography>
              </Box>

              {/* Network Section */}
              <Box
                sx={{
                  backgroundColor: "#101840",
                  padding: "1rem",
                  borderRadius: "8px",
                  flex: "1",
                  minWidth: "150px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                >
                  <LiaSignalSolid />
                  Network
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Speed: {trackData.speed} km/h
                </Typography>
                {trackData["Additional Data"] && (
                  <Typography variant="body2">
                    Satellites:{" "}
                    {trackData["Additional Data"].find((item: any) =>
                      item.hasOwnProperty("satellites")
                    )?.satellites || "N/A"}
                  </Typography>
                )}
                <Typography variant="body2">Bearing: 0°</Typography>
              </Box>

              {/* Location Section */}
              <Box
                sx={{
                  backgroundColor: "#101840",
                  padding: "1rem",
                  borderRadius: "8px",
                  flex: "1",
                  minWidth: "180px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                >
                  <FaMapPin />
                  Location
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Latitude: {trackData.latitude}
                </Typography>
                <Typography variant="body2">
                  Longitude: {trackData.longitude}
                </Typography>
                <Typography variant="body2">Address: {address}</Typography>
              </Box>

              {/* Ignition Section */}
              <Box
                sx={{
                  backgroundColor: "#101840",
                  padding: "1rem",
                  borderRadius: "8px",
                  flex: "1",
                  minWidth: "120px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                >
                  <FaCarBattery />
                  Ignition
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Ignition:{" "}
                  {!trackData?.statusBitDefinition?.ignitionOn ? (
                    <span>On</span>
                  ) : (
                    <span>Off</span>
                  )}
                </Typography>
              </Box>

              {/* Battery Section */}
              <Box
                sx={{
                  backgroundColor: "#101840",
                  padding: "1rem",
                  borderRadius: "8px",
                  flex: "1",
                  minWidth: "120px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                >
                  <FaCarBattery />
                  Battery
                </Typography>
                {trackData["Additional Data"] && (
                  <>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Battery Level:{" "}
                      {trackData["Additional Data"].find((item: any) =>
                        item.hasOwnProperty("batteryPercentage")
                      )?.batteryPercentage || "N/A"}{" "}
                      %
                    </Typography>
                    <Typography variant="body2">
                      Battery Voltage:{" "}
                      {trackData["Additional Data"].find((item: any) =>
                        item.hasOwnProperty("batteryVoltage")
                      )?.batteryVoltage || "N/A"}{" "}
                      mV
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ViewLiveTracking;
