export const addDevicetable = [
  {
    name: "IMEI",
    field: "imei",
  },
  {
    name: "Device Id",
    field: "deviceId",
  },
  {
    name: "Model Name",
    field: "deviceModelName",
  },

  {
    name: "Model Type",
    field: "deviceModelType",
  },

  // {
  //   name: "Entered By",
  //   field: "createdBy",
  // },

  {
    name: "Action",
    field: "action",
  },
];
